import React from 'react';
import _ from 'lodash';
import { Helmet } from 'react-helmet';

import Layout from '../components/layout';

function litersToGallons(liters) {
  return 0.264172 * liters;
}

function lbsToGrams(lbs) {
  return 453.592 * lbs;
}

function ogToPoints(og) {
  return (og - 1) * 1000;
}

export default () => {
  const [og, setOG] = React.useState(1.037);
  const [dmePpg, setDmePpg] = React.useState(1.043);
  const [starter, setStarter] = React.useState(1);
  const [starterUnits, setStarterUnits] = React.useState('L');

  const starterGal = starterUnits === 'L' ? litersToGallons(starter) : starter;
  const pointsNeeded = ogToPoints(og) * starterGal;
  const starterNeededLbs = pointsNeeded / ogToPoints(dmePpg);
  const starterNeededGrams = lbsToGrams(starterNeededLbs);

  return (
    <Layout>
      <Helmet>
        <title>DME Calculator</title>
      </Helmet>
      <h1>DME Calculator</h1>
      <p>
        Enter your desired original gravity and starter size in liters or
        gallons to calculate the amount of DME to add in grams.
      </p>
      <div className="columns is-vcentered">
        <div className="column">
          <form>
            <label className="label" htmlFor="og">
              Original Gravity (OG)
            </label>
            <div className="field">
              <div className="control">
                <input
                  className="input"
                  type="text"
                  id="og"
                  name="og"
                  value={og}
                  onChange={e => setOG(e.target.value)}
                />
              </div>
            </div>
            <label className="label" htmlFor="starter">
              Starter Size
            </label>
            <div className="field has-addons">
              <div className="control is-expanded">
                <input
                  className="input"
                  type="text"
                  id="starter"
                  name="starter"
                  value={starter}
                  onChange={e => setStarter(e.target.value)}
                />
              </div>
              <div className="control">
                <span className="select">
                  <select
                    value={starterUnits}
                    onChange={e => setStarterUnits(e.target.value)}
                    onBlur={e => setStarterUnits(e.target.value)}
                  >
                    <option value="L">Liters</option>
                    <option value="G">Gallons</option>
                  </select>
                </span>
              </div>
            </div>
            <label className="label" htmlFor="dmePpg">
              DME Points per Pound per Gallon (PPG)
            </label>
            <div className="field">
              <div className="control">
                <input
                  className="input"
                  type="text"
                  id="dmePpg"
                  name="dmePpg"
                  value={dmePpg}
                  onChange={e => setDmePpg(e.target.value)}
                />
              </div>
            </div>
          </form>
        </div>
        <div className="column">
          <table className="table is-bordered is-hoverable">
            <tbody>
              <tr>
                <td>
                  <strong>DME Amount</strong>
                </td>
                <td>{_.round(starterNeededGrams)} g</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  );
};
